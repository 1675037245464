.sales_table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.sales_table th,
.sales_table td {
    padding: 0.75rem;
    vertical-align: middle;
    border: 1px solid #dee2e6;
}

.sales_table th {
    font-weight: 500;
    background-color: #f8f9fa;
}

.sales_table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.sales_table input.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.sales_table .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
