.react-datepicker__month-select,
.react-datepicker__year-select {
    appearance: none;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    color: #212529;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.25rem 2.25rem 0.25rem 0.75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
}

.react-datepicker__month-select:focus,
.react-datepicker__year-select:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    outline: 0;
}

.react-datepicker__month-select:hover,
.react-datepicker__year-select:hover {
    border-color: #86b7fe;
}

.react-datepicker__month-select option,
.react-datepicker__year-select option {
    background-color: #fff;
    color: #212529;
    padding: 0.375rem 0.75rem;
}

.react-datepicker__month-select option:hover,
.react-datepicker__year-select option:hover {
    background-color: #e9ecef;
}

.react-datepicker__month-select option:checked,
.react-datepicker__year-select option:checked {
    background-color: #0d6efd;
    color: #fff;
}

/* Make sure the container has proper spacing */
.react-datepicker__header__dropdown {
    margin: 0.5rem 0;
}

.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
    margin: 0 0.25rem;
}
